import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import * as dat from "dat.gui";

const gui = new dat.GUI();

const textureLoader = new THREE.TextureLoader();
const cubeTextureLoader = new THREE.CubeTextureLoader();
const doorAlphaMap = textureLoader.load("../textures/door/alpha.jpg");
const doorHeightMap = textureLoader.load("../textures/door/height.jpg");
const doorNormalMap = textureLoader.load("../textures/door/normal.jpg");
const doorColorMap = textureLoader.load("../textures/door/color.jpg");
const doorMetalnessMap = textureLoader.load("../textures/door/metalness.jpg");
const doorRoughnessMap = textureLoader.load("../textures/door/roughness.jpg");
const doorAmbientOcclusionMap = textureLoader.load(
  "../textures/door/ambientOcclusion.jpg"
);
const MatcapMap = textureLoader.load("../textures/matcaps/5.png");
const envMapTexture = cubeTextureLoader.load([
  "../textures/environmentMaps/3/px.jpg",
  "../textures/environmentMaps/3/nx.jpg",
  "../textures/environmentMaps/3/py.jpg",
  "../textures/environmentMaps/3/ny.jpg",
  "../textures/environmentMaps/3/pz.jpg",
  "../textures/environmentMaps/3/nz.jpg",
]);
/**
 * Base
 */
// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();
scene.background = envMapTexture;
/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

/*
 * Object
 * */
const material = new THREE.MeshStandardMaterial({
  metalness: 1,
  roughness: 0,
});
material.envMap = envMapTexture;

// material.map = doorColorMap;
// material.aoMap = doorAmbientOcclusionMap;
// material.displacementMap = doorHeightMap;
// material.displacementScale = 0.05;
// material.metalnessMap = doorMetalnessMap;
// material.roughnessMap = doorRoughnessMap;
// material.normalMap = doorNormalMap;
// material.normalScale.set(0.5, 0.5);
// material.alphaMap = doorAlphaMap;
// material.transparent = true;

gui.add(material, "metalness").min(0).max(1).step(0.0001);
gui.add(material, "roughness").min(0).max(1).step(0.0001);
gui.add(material, "aoMapIntensity").min(1).max(10).step(0.0001);
gui.add(material, "displacementScale").min(0).max(1).step(0.0001);

// material.specular = new THREE.Color("red");
// material.flatShading = true;
// material.stencilWrite = true;
// material.color.set("yellow");
// material.opacity = 0.5;
// material.transparent = true;
// material.alphaMap = doorAlphaMap;
// material.wireframe = true;
const sphere = new THREE.Mesh(new THREE.SphereGeometry(0.5, 64, 64), material);
sphere.position.x = -1.5;
const plane = new THREE.Mesh(new THREE.PlaneGeometry(1, 1, 100, 100), material);
const torus = new THREE.Mesh(
  new THREE.TorusGeometry(0.3, 0.2, 64, 128),
  material
);
torus.position.x = 2;
scene.add(sphere, plane, torus);

plane.geometry.setAttribute(
  "uv2",
  new THREE.BufferAttribute(plane.geometry.attributes.uv.array, 2)
);
sphere.geometry.setAttribute(
  "uv2",
  new THREE.BufferAttribute(sphere.geometry.attributes.uv.array, 2)
);
torus.geometry.setAttribute(
  "uv2",
  new THREE.BufferAttribute(torus.geometry.attributes.uv.array, 2)
);
/**
 * Lights
 * */

const ambientLight = new THREE.AmbientLight();
const pointLight = new THREE.PointLight();
pointLight.position.x = 2;
pointLight.position.y = 3;
scene.add(ambientLight, pointLight);

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  75,
  sizes.width / sizes.height,
  0.1,
  100
);
camera.position.x = 1;
camera.position.y = 1;
camera.position.z = 2;
scene.add(camera);

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

/**
 * Animate
 */
const clock = new THREE.Clock();

const tick = () => {
  const elapsedTime = clock.getElapsedTime();

  // plane.rotation.z = 0.5 * elapsedTime;
  // sphere.rotation.z = 0.5 * elapsedTime;
  // torus.rotation.z = 0.5 * elapsedTime;

  // plane.rotation.x = 0.5 * elapsedTime;
  // sphere.rotation.x = 0.5 * elapsedTime;
  // torus.rotation.x = 0.5 * elapsedTime;
  // Update controls
  controls.update();
  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
